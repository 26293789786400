import { useHistory } from '@leagueplatform/routing';
import { useAuth } from '@leagueplatform/auth-utils';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';

export const AuthSessionContainer = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const history = useHistory();
  const { isAuthenticated, error } = useAuth();

  if (isAuthenticated === undefined) {
    return <FullPageLoadingSpinner />;
  }

  if (error || !isAuthenticated) {
    history.push('/');
    return null;
  }

  return children;
};
