import { BoxProps } from '@leagueplatform/genesis-commons';
import { FAQ } from 'common/constants';
import type { Link } from 'common/types';
import manageProfileIcon from 'assets/manage-account-icon.png';

export type Options = {
  heading?: string;
  items: Link[];
};

const boldTextLink: BoxProps = {
  color: 'onSurface.text.subdued',
  fontWeight: 'bold',
};

const boldTextCriticalLink: BoxProps = {
  color: 'onSurface.text.critical',
  fontWeight: 'bold',
};

export const useUserDropdownConfig = (
  handleDeleteAccount: () => void,
): Options[] => [
  {
    heading: 'ACCOUNT_SETTINGS',
    items: [
      {
        text: 'PERSONAL_INFO',
        to: 'manage-profile',
        color: '$onSurfaceTextSubdued',
        icon: manageProfileIcon,
        linkIcon: 'interfaceChevronRight',
      },
    ],
  },
  {
    heading: 'SUPPORT',
    items: [
      {
        text: 'FAQ',
        target: '_blank',
        to: `${FAQ}`,
        linkProps: boldTextLink,
        color: '$onSurfaceTextSubdued',
        icon: 'illustrativePerson',
        linkIcon: 'interfaceExternalLink',
      },
    ],
  },

  {
    items: [
      {
        text: 'LOG_OUT',
        to: '/sign-out',
        color: '$onSurfaceTextCritical',
        linkProps: boldTextCriticalLink,
        icon: 'interfaceSignOut',
        linkIcon: 'interfaceChevronRight',
        onClick: handleDeleteAccount,
      },
    ],
  },
];
