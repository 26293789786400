import { useState } from 'react';
import {
  AppBarItem,
  AppBarNavLink,
  AppBarHeader,
  AppBarNav,
  AppBarNavList,
} from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import {
  queryHelpers,
  useMediaQuery,
  Button,
  Modal,
  Box,
} from '@leagueplatform/genesis-core';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { UserDropdown } from 'components/header-nav/navbar/user-dropdown/user-dropdown.component';
import { APP_NAV_DEFAULT_HEIGHT } from 'components/header-nav/navbar/nav.constants';
import {
  NavLink,
  useNavLinksList,
} from 'components/header-nav/navbar/use-nav-links-list.hook';
import { MobileNav } from 'components/header-nav/navbar/mobile-nav/mobile-nav.component';
import { sendCapabilityNavLinkEvent } from 'utils/analytics-events';
import { useModal } from 'hooks/use-modal.hook';
import { DeleteAccountModal } from '../../modals/delete-account-modal.component';
import { AccountDeletedModal } from '../../modals/account-deleted-modal.component';

export const AppNavBarComponent = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { navLinks } = useNavLinksList();
  const { formatMessage } = useIntl();
  const isAboveLaptop = useMediaQuery(queryHelpers.up('laptop'));
  // const isMobile = useMediaQuery(queryHelpers.between([0, 'tablet']));
  const {
    isShowing: showDeleteAccountModal,
    toggle: toggleDeleteAccountModal,
  } = useModal();
  const {
    isShowing: showAccountDeletedModal,
    toggle: toggleAccountDeletedModal,
  } = useModal();

  const handleAccountDeleted = () => {
    StandaloneAuth.client.logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <Box
      css={{
        // header: {
        //   paddingLeft: isMobile ? '$none' : '$five',
        // },
        '> header > nav > ul > li > a[aria-current="page"]::after': {
          backgroundColor: '$interactiveActionPrimary',
        },
      }}
    >
      <AppBarHeader navHeight={APP_NAV_DEFAULT_HEIGHT}>
        {isAboveLaptop ? (
          // Desktop Navigation
          <AppBarNav>
            <AppBarNavList>
              {/* App Nav Home & Capability Areas */}
              {navLinks.map((navLink: NavLink) => (
                <AppBarNavLink
                  to={navLink.to}
                  key={navLink.message_id}
                  onClick={sendCapabilityNavLinkEvent(navLink.message_id)}
                  color="onSurface.text.primary"
                  fontWeight="medium"
                  hoverStyle={{ color: 'interactive.action.primary' }}
                  decorativeBarColor="interactive.action.primary"
                  listItemProps={{
                    flexGrow: 0,
                    marginRight: 'twoAndHalf',
                  }}
                >
                  {formatMessage({ id: navLink.message_id })}
                </AppBarNavLink>
              ))}
              <AppBarItem
                justifyContent="flex-end"
                marginRight={isAboveLaptop ? 'five' : 0}
              >
                {/* App Nav User Dropdown */}
                <UserDropdown handleDeleteAccount={toggleDeleteAccountModal} />
              </AppBarItem>
            </AppBarNavList>
          </AppBarNav>
        ) : (
          // Mobile Navigation
          <Modal.Root
            open={menuOpen}
            onOpenChange={() => {
              setMenuOpen((previous) => !previous);
            }}
          >
            {/* @ts-ignore */}
            <Modal.Trigger>
              <Button
                icon="interfaceMenu"
                hideLabel
                quiet
                priority="tertiary"
                size="large"
                css={{
                  margin: '$half $quarter',
                }}
                aria-controls={undefined}
              >
                {formatMessage({ id: 'OPEN_MENU' })}
              </Button>
            </Modal.Trigger>
            <Modal.Content
              layout="right"
              css={{
                '.GDS-modal': { maxWidth: 335 },
                '.GDS-modal-content': { paddingLeft: '$one' },
              }}
            >
              <MobileNav
                closeModal={() => setMenuOpen(false)}
                handleDeleteAccount={toggleDeleteAccountModal}
              />
            </Modal.Content>
          </Modal.Root>
        )}
      </AppBarHeader>
      <Modal.Root
        open={showDeleteAccountModal}
        onOpenChange={toggleDeleteAccountModal}
      >
        <DeleteAccountModal onSubmit={toggleAccountDeletedModal} />
      </Modal.Root>
      <Modal.Root
        open={showAccountDeletedModal}
        onOpenChange={() => {
          toggleAccountDeletedModal();
          handleAccountDeleted();
        }}
      >
        <AccountDeletedModal />
      </Modal.Root>
    </Box>
  );
};
